import PropTypes from "prop-types"
import React from "react"
import { graphql, useStaticQuery } from "gatsby"

const Header = ({
  siteTitle,
  isForSubPages = false,
}: {
  siteTitle?: string
  isForSubPages?: boolean
}) => {
  const data = useStaticQuery(graphql`
    query {
      allDataYaml {
        nodes {
          features {
            title
            text
            url
            img {
              src
            }
          }
        }
      }
    }
  `)
  return (
    <header className={`main-header ${isForSubPages ? "isForSubPages" : ""}`}>
      <nav>
        <ul>
          <li>
            <a href="/">Home</a>
          </li>
          <li>
            <div className="dropdown">
              <div className="label">Features</div>
              <ul className="dropdown-content">
                {data.allDataYaml.nodes
                  .find((n: any) => !!n.features)
                  .features // TODO remove once we have more content
                  .filter((item: any, i: number) => !!item.url)
                  .map((item: any, i: number) => {
                    return (
                      <li key={i}>
                        <a href={"/" + item.url}>{item.title}</a>
                      </li>
                    )
                  })}
              </ul>
            </div>
          </li>
          {/*<li>*/}
          {/*  <a href="#features">Features</a>*/}
          {/*</li>*/}
          {/*<li>*/}
          {/*  <a href="/about">About</a>*/}
          {/*</li>*/}
          <li>
            <a href="/blog">Blog</a>
          </li>

          <li>
            <div className="dropdown">
              <div className="label">Support</div>
              <ul className="dropdown-content">
                <li>
                  <a href="https://github.com/johannesjo/super-productivity/discussions?discussions_q=category%3AQ%26A">
                    Questions & Answers
                  </a>
                </li>
                <li>
                  <a href="https://github.com/johannesjo/super-productivity/issues/new?assignees=&labels=bug&template=BUG_REPORT.md&title=">
                    Report a Problem
                  </a>
                </li>
                <li>
                  <a href="https://github.com/johannesjo/super-productivity/issues/new?assignees=&labels=enhancement&template=FEATURE_REQUEST.md&title=">
                    Request a Feature
                  </a>
                </li>
                <li>
                  <a href="https://github.com/johannesjo/super-productivity/discussions">
                    Discuss
                  </a>
                </li>
              </ul>
            </div>
          </li>
          <li>
            <a href="/about">About</a>
          </li>
        </ul>
      </nav>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
